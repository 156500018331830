import React from "react"

const NoMatch = () => (
  <section>
    <h1 className="fs-10rem bold text-center">404</h1>
    <p className="fs-3rem text-center">Nothing here 🌵</p>
  </section>
)

export default NoMatch
