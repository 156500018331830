import React, { useEffect } from "react"
import { Helmet } from "@/components/Helmet"
import { Link } from "react-router-dom"
import Footer from "@/components/Footer"
import { TextInput } from "@/components/Forms"
import { css, styled } from "@/theme"
import { useDispatch, useSelector } from "@/hooks"
import { push } from "connected-react-router"
import { fetchingRecipeListAsync } from "@/store/thunks"
import { getTeamRecipes } from "@/store/reducers/recipes"
import { searchRecipes } from "@/search"

const SearchInput = styled(TextInput)`
  font-size: 1.5rem !important;
  margin-bottom: 0.25rem;
`

const SearchInputAligner = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  padding-bottom: 4rem;
`

const SearchInputContainer = styled.div`
  max-width: 400px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
`

const SearchOptions = styled.div`
  font-size: 0.85rem;
  color: ${props => props.theme.color.muted};
`

const Code = styled.code`
  margin: 0 2px;
  padding: 0px 5px;
  border: 1px solid #ddd;
  background-color: #f8f8f8;
  border-radius: 3px;
  color: inherit;
  white-space: pre;
`

const SectionTitle = styled.div`
  /* font-weight: bold; */
  font-size: 1.25rem;
  /* text-decoration: underline; */
`

const ScheduledRecipeContainer = styled.div`
  display: flex;
`
const Day = styled.div`
  font-weight: bold;
  text-align: right;
  min-width: 2.5rem;
  margin-right: 0.5rem;
  color: hsl(0 0% 40% / 1);
`
const Recipes = styled.div`
  display: inline-grid;
`

const Recipe = styled(Link)`
  font-weight: bold;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

function ScheduledRecipe(props: {
  readonly day: string
  readonly recipes: { readonly id: string; readonly name: string }[]
}) {
  return (
    <ScheduledRecipeContainer>
      <Day>{props.day}</Day>
      <Recipes>
        {props.recipes.length === 0 ? (
          <div className="text-muted">—</div>
        ) : null}
        {props.recipes.map(x => (
          <Recipe key={x.id} to={`/recipes/${x.id}`}>
            {x.name}
          </Recipe>
        ))}
      </Recipes>
    </ScheduledRecipeContainer>
  )
}

const Caption = styled.div`
  font-size: 0.8rem;
  color: ${props => props.theme.color.muted};
`
const ActivityContainer = styled.div`
  margin-bottom: 0.5rem;
`

function Activity(props: {
  readonly actor: string
  readonly action: string
  readonly recipe: { readonly id: string; readonly name: string }
  readonly time: string
}) {
  return (
    <ActivityContainer>
      <div>
        <b>{props.actor}</b> {props.action}{" "}
        <b>
          <Link to={`/recipes/${props.recipe.id}`}>{props.recipe.name}</Link>
        </b>
      </div>
      <Caption>{props.time}</Caption>
    </ActivityContainer>
  )
}

const ScheduleContainer = styled.div`
  max-width: 300px;
`

const SuggestionBox = styled.div`
  position: absolute;
  z-index: 10;
  top: 60px;
  background: white;
  max-width: 400px;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: #dddd;
  border-radius: 5px;
  box-shadow: 0px 4px 5px 0px hsl(0 0% 90% / 1);
  padding: 0.25rem;
  display: inline-grid;
`

const suggestionStyle = css`
  padding: 0.25rem 0.25rem;

  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const SuggestionInfo = styled.div`
  ${suggestionStyle}
  text-align: center;
  color: ${props => props.theme.color.muted};
`

const SuggestionItem = styled(Link)<{ readonly firstItem?: boolean }>`
  ${suggestionStyle}
  // Underline the first item because we navigate to it on "Enter".
  ${props => props.firstItem && "text-decoration: underline;"}
`

const BrowseRecipes = styled(Link)`
  ${suggestionStyle}
  border-top-style: solid;
  border-top-width: 1px;
  border-color: #f2f2f2;
  margin-top: 0.5rem;
  text-align: center;
`

const UserHome = () => {
  const [searchQuery, setSearchQuery] = React.useState("")
  const recipes = useSelector(s => getTeamRecipes(s, "personal"))
  const dispatch = useDispatch()
  useEffect(() => {
    fetchingRecipeListAsync(dispatch)("personal")
  }, [dispatch])
  const setQuery = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchQuery(event.target.value)
    },
    [],
  )

  const filteredRecipes =
    recipes?.kind === "Success"
      ? searchRecipes({ recipes: recipes.data, query: searchQuery })
      : []

  const loadingSuggestions = recipes?.kind !== "Success"

  const suggestions = filteredRecipes
    .map((recipe, index) => {
      return (
        <SuggestionItem
          key={recipe.id}
          to={`/recipes/${recipe.id}`}
          firstItem={index === 0}>
          <b>{recipe.name}</b> by {recipe.author}
        </SuggestionItem>
      )
    })
    .slice(0, 7)

  const handleSearchKeydown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const key = e.key
      const suggestion = filteredRecipes[0]
      if (!suggestion) {
        return
      }
      if (key === "Enter") {
        dispatch(push(`/recipes/${suggestion.id}`))
      }
    },
    [dispatch, filteredRecipes],
  )

  return (
    <>
      <div className="container pr-2 pl-2 pb-2">
        <Helmet title="Home" />
        <SearchInputAligner>
          <SearchInputContainer>
            <SearchInput
              autoFocus
              value={searchQuery}
              onChange={setQuery}
              onKeyDown={handleSearchKeydown}
              placeholder="Search your recipes..."
            />
            {searchQuery && (
              <SuggestionBox>
                {!loadingSuggestions ? (
                  <>
                    {suggestions.length === 0 && (
                      <SuggestionInfo>No Results Found</SuggestionInfo>
                    )}
                    {suggestions}
                    <BrowseRecipes
                      to={{
                        pathname: "/recipes",
                        search: `search=${encodeURIComponent(searchQuery)}`,
                      }}>
                      Browse Recipes
                    </BrowseRecipes>
                  </>
                ) : (
                  <SuggestionInfo>Loading...</SuggestionInfo>
                )}
              </SuggestionBox>
            )}
            <SearchOptions>
              fields <Code>author:Jane Doe</Code>,{" "}
              <Code>ingredient:onions</Code>, <Code>name:cake</Code>
            </SearchOptions>
          </SearchInputContainer>
        </SearchInputAligner>

        <div className="home-page-grid">
          <ScheduleContainer>
            <SectionTitle>Schedule</SectionTitle>
            <div>
              <ScheduledRecipe
                day="Tue"
                recipes={[{ id: "123123", name: "Beef Burgundy" }]}
              />
              <ScheduledRecipe day="Wed" recipes={[]} />
              <ScheduledRecipe
                day="Thu"
                recipes={[{ id: "dlkfjsdf", name: "Texas Chili" }]}
              />
              <ScheduledRecipe
                day="Fri"
                recipes={[{ id: "12312391", name: "Texas Chili" }]}
              />
              <ScheduledRecipe
                day="Sat"
                recipes={[
                  { id: "alkdjflsdjf", name: "Chocolate Chip Cookies" },
                ]}
              />
              <ScheduledRecipe
                day="Sun"
                recipes={[
                  {
                    id: "asdlkfjasdlf",
                    name:
                      "Roasted Cauliflower With Pancetta, Olives and Crisp Parmesan",
                  },
                  { id: "923409234", name: "Café China’s Dan Dan Noodles" },
                ]}
              />
              <ScheduledRecipe day="Mon" recipes={[]} />
            </div>
          </ScheduleContainer>
          <div>
            <SectionTitle>Recent Activity</SectionTitle>
            <div>
              <Activity
                actor="Chris"
                action="commented on"
                recipe={{ id: "alsdkfjalsdf", name: "Butter Chicken" }}
                time="One day ago"
              />
              <Activity
                actor="Steve"
                action="added"
                recipe={{ id: "asd;flkasdf", name: "Brandied Pumpkin Pie" }}
                time="One day ago"
              />
              <Activity
                actor="Steve"
                action="archived"
                recipe={{ id: "aasdfasdf", name: "Butter Chicken" }}
                time="One day ago"
              />
              <Activity
                actor="Chris"
                action="archived"
                recipe={{ id: "asdfasdfadsf", name: "Texas Chili" }}
                time="Three days ago"
              />
            </div>
          </div>

          <div>
            <SectionTitle>Frequently Scheduled</SectionTitle>
            <div>
              <div>
                <b>
                  <Link to="/recipes/123123">Chicken Provincal</Link>
                </b>{" "}
                has been scheduled <b>10 times</b> in the last 6 months
              </div>
              <div>
                <b>
                  <Link to="/recipes/12390123">
                    Oven-roasted chicken shawarama
                  </Link>
                </b>{" "}
                has been scheduled <b>5 times</b> in the last 6 months
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

export default UserHome
